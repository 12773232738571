import mixpanel, { OverridedMixpanel } from "mixpanel-browser"

export type MixpanelToken = {
  projectToken: string
  isDebug: boolean
}

export const initMixpanel = ({
  projectToken,
  isDebug
}: MixpanelToken): OverridedMixpanel | undefined => {
  mixpanel.init(projectToken, {
    debug: isDebug
  })
  return mixpanel
}

export default mixpanel
