//components
import Lottie from "react-lottie-player/dist/LottiePlayerLight"
import Loading from "@/assets/lotties/logo TMN Loading.json"

interface Props {
  message: string
}

const TMNProcessingLogo = ({ message }: Props) => {
  return (
    <div
      className="!z-[1001]"
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <Lottie animationData={Loading} loop={true} play={true} style={{ height: 72 }} />
      <span data-testid="tmn-loading" className="body-large">
        {message}
      </span>
    </div>
  )
}

export default TMNProcessingLogo
