import * as braze from "@braze/web-sdk"

export type BrazeToken = {
  appId: string
  restSDK: string
  isDebug: boolean
}

export const initBraze = ({ appId, restSDK, isDebug }: BrazeToken) => {
  braze.initialize(appId, {
    baseUrl: restSDK,
    enableLogging: isDebug
  })
  braze.openSession()
  return braze
}

export default braze
