import braze from "@/utils/BrazeConfig"
import mixpanel from "@/utils/MixpanelConfig"
import dayjs from "dayjs"

const brazeSetCustomUserAttribute = (attributes: Record<string, any>) => {
  const user = braze.getUser()
  if (user) {
    Object.entries(attributes).forEach(([key, value]) => {
      user.setCustomUserAttribute(key, value)
    })
  }
}

export const startSession = (tmnId: string) => {
  mixpanel.identify(tmnId)
  braze.changeUser(tmnId)
}

const sendToPublisher = (
  eventName: string | undefined,
  eventProps: Record<string, any> | undefined,
  peopleProps: Record<string, any> | undefined
) => {
  // Mixpanel
  sendToMixpanel(eventName, eventProps, peopleProps)
  // Braze
  sendToBraze(eventName, eventProps, peopleProps)
}

const sendToMixpanel = (
  eventName: string | undefined,
  eventProps: Record<string, any> | undefined,
  peopleProps: Record<string, any> | undefined
) => {
  if (eventName && eventProps) {
    mixpanel.track(eventName, eventProps)
  }
  if (peopleProps) {
    mixpanel.people.set(peopleProps)
  }
}

const sendToBraze = (
  eventName: string | undefined,
  eventProps: Record<string, any> | undefined,
  peopleProps: Record<string, any> | undefined
) => {
  if (eventName && eventProps) {
    braze.logCustomEvent(eventName, eventProps)
  }
  if (peopleProps) {
    brazeSetCustomUserAttribute(peopleProps)
  }
}

const getExpiryDate = (expiry: string, isBraze: boolean) => {
  if (expiry && dayjs(expiry).isValid() && expiry !== "1970-01-01T00:00:00Z") {
    return (
      dayjs
        .utc(expiry)
        .subtract(1, "second")
        .locale("en")
        // Date format for Braze see here: https://www.braze.com/docs/api/objects_filters/user_attributes_object
        .format(isBraze ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD")
    )
  }
  return ""
}

/**
 * Track the home page visit(Dashboard page)
 */
export const trackHomePage = async ({
  balance,
  expiry,
  totalCoins
}: {
  totalCoins: number
  balance: number
  expiry: string
}) => {
  const expiryDate = getExpiryDate(expiry, false)
  const brazeExpiryDate = getExpiryDate(expiry, true)

  const eventName = "TMN Coin - Landing"
  const eventProps = {
    "Total Coin": totalCoins,
    abc_TMC_balance: balance,
    abc_TMC_expiry: expiryDate
  }
  const peopleProps = {
    abc_TMC_balance: balance,
    abc_TMC_expiry: expiryDate
  }
  sendToMixpanel(eventName, eventProps, peopleProps)

  // need to adjust the date format for Braze
  eventProps.abc_TMC_expiry = brazeExpiryDate
  peopleProps.abc_TMC_expiry = brazeExpiryDate
  sendToBraze(eventName, eventProps, peopleProps)
}

export const trackTMNCoinClickButton = async ({ buttonName }: { buttonName: string }) => {
  const eventName = "TMN Coin - Click Button"
  const eventProps = {
    "Button name": buttonName
  }
  sendToPublisher(eventName, eventProps, undefined)
}

export const trackTMNCoinError = async ({
  errorCode,
  errorMessage
}: {
  errorCode: string
  errorMessage: string
}) => {
  const eventName = "TMN Coin - Error"
  const eventProps = {
    "Error Code": errorCode,
    "Error Message": errorMessage
  }
  sendToPublisher(eventName, eventProps, undefined)
}

export const tackTMNViewCouponDetail = async ({
  couponId,
  couponName,
  price,
  couponStage,
  couponType,
  merchantName
}: {
  couponId: string
  couponName: string
  price: number
  couponStage: string
  couponType: string
  merchantName: string
}) => {
  const eventName = "TMN Coin - View Coupon Detail"
  const eventProps = {
    "Coupon ID": couponId,
    "Coupon name": couponName,
    Price: price,
    "Coupon Stage": couponStage,
    "Coupon type": couponType,
    "Merchant name": merchantName
  }
  sendToPublisher(eventName, eventProps, undefined)
}

export enum TabName {
  Received = "Received",
  Used = "Used",
  Expired = "Expired"
}
export const trackViewHistoryPage = async (tabName: TabName) => {
  const eventName = "TMN Coin - View History page"
  const eventProps = {
    "Tab name": tabName
  }
  sendToPublisher(eventName, eventProps, undefined)
}

export const trackViewMarketplacePage = async () => {
  const eventName = "TMN Coin - View Redemption page"
  const eventProps = {}
  sendToPublisher(eventName, eventProps, undefined)
}

export const trackViewMarketplaceSectionPage = async (sectionName: string) => {
  const eventName = "TMN Coin - View Marketplace section page"
  const eventProps = {
    "Section Name": sectionName
  }
  sendToPublisher(eventName, eventProps, undefined)
}

export const trackViewCouponsPage = async (type: string) => {
  const eventName = "TMN Coin - View My Coupon page"
  const eventProps = {
    "Tab name": type
  }
  sendToPublisher(eventName, eventProps, undefined)
}
